// Theme Presets
const lightTheme = {
  name: "light",
  body: "#fff",
  text: "#343434",
  dark: "#31071A",
  secondaryText: "#7F8DAA",
  ternaryText: "#FFFFFF",
  themeColor: "#FFFFFF",
  oppositeThemeColor: "#000000",
  accentColor: "#E3405F",
  accentBright: "#FC1056",
  projectCard: "#DCE4F2",
  skinColor: "#F7B799",
  skinColor2: "#FCB696",
  imageDark: "#dce4f2",
  imageClothes: "#dce4f2",
  avatarMisc: "#e9ecf2",
  avatarShoes: "#ccd2e3",
  expTxtColor: "#000a12",
  highlight: "#FBA7CD",
  imageHighlight: "#ef476f",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#8d99ae",
};

const darkTheme = {
  name: "dark",
  body: "#1D1D1D",
  text: "#FFFFFF",
  dark: "#000000",
  secondaryText: "#8D8D8D",
  ternaryText: "#FFFFFF",
  themeColor: "#1d1d1d",
  oppositeThemeColor: "#FFFFFF",
  accentColor: "#E3405F",
  accentBright: "#FC1056",
  projectCard: "#292A2D",
  skinColor: "#F7B799",
  skinColor2: "#FCB696",
  imageDark: "#292A2D",
  imageClothes: "#000000",
  avatarMisc: "#212121",
  avatarShoes: "#2B2B2B",
  expTxtColor: "#000a12",
  highlight: "#FBA7CD",
  imageHighlight: "#ef476f",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#8d99ae",
};

export const themes = { light: lightTheme, dark: darkTheme };
